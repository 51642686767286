import { useNavigate, useSearchParams } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { $path } from 'remix-routes';
import useSWR from 'swr';

import { type ModelsStack } from '@lp-lib/api-service-client/public';

import { gamePackToEnrollmentTarget } from '../../app/components/GamePack/utils';
import { SegmentedControl } from '../components/common/SegmentedControl';
import { type Option } from '../components/common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { CreateCourseButton } from '../components/Training/Admin/Components/CreateCourseButton';
import { PaginationNav } from '../components/Training/Admin/Components/PaginatedNav';
import { type EnrollmentTarget } from '../components/Training/Admin/Modals/CourseAssignmentModal';
import { useTriggerCreateStackModal } from '../components/Training/Admin/Modals/CreateStackModal';
import { DeleteCourseConfirmationModal } from '../components/Training/Admin/Modals/DeleteCourseConfirmationModal';
import { useEnrollmentActions } from '../components/Training/Admin/useCourseActions';
import { LongCourseCardList } from '../components/Training/LongCourseCardList';
import { StackCardList } from '../components/Training/StackCardList';
import { TrainingGenAIStarter } from '../components/Training/TrainingStarter';
import { useDebouncedValue } from '../hooks/useDebouncedValue';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { getLogger } from '../logger/logger';
import { apiService } from '../services/api-service';
import { type GamePack } from '../types/game';
import { fromDTOGamePack } from '../utils/api-dto';

const logger = getLogger().scoped('My Courses');

export function useCreatedCoursesAndStacks() {
  const [coursesPage, setCoursesPage] = useState(1);
  const [stacksPage, setStacksPage] = useState(1);
  const [coursesPageSize, setCoursesPageSize] = useState(10);
  const [stacksPageSize, setStacksPageSize] = useState(10);
  const [courseSearchQuery, setCourseSearchQuery] = useState('');
  const [stackSearchQuery, setStackSearchQuery] = useState('');
  const [courseSearchResults, setCourseSearchResults] = useState<GamePack[]>(
    []
  );
  const [stackSearchResults, setStackSearchResults] = useState<ModelsStack[]>(
    []
  );
  const [isSearchingCourses, setIsSearchingCourses] = useState(false);
  const [isSearchingStacks, setIsSearchingStacks] = useState(false);

  const debouncedCourseQuery = useDebouncedValue(courseSearchQuery, {
    settleAfterMs: 300,
    keepPreviousValue: false,
  });

  const debouncedStackQuery = useDebouncedValue(stackSearchQuery, {
    settleAfterMs: 300,
    keepPreviousValue: false,
  });

  const {
    data: coursesData,
    error: coursesError,
    isLoading: coursesLoading,
    mutate: refreshCourses,
  } = useSWR([`/courses/created`, coursesPage, coursesPageSize], () =>
    apiService.learning.getCreatedCourses(coursesPage, coursesPageSize)
  );

  const {
    data: stacksData,
    error: stacksError,
    isLoading: stacksLoading,
    mutate: refreshStacks,
  } = useSWR([`/stacks/created`, stacksPage, stacksPageSize], () =>
    apiService.learning.getCreatedStacks(stacksPage, stacksPageSize)
  );

  const handleCoursesPageChange = useCallback((newPage: number) => {
    setCoursesPage(newPage);
  }, []);

  const handleStacksPageChange = useCallback((newPage: number) => {
    setStacksPage(newPage);
  }, []);

  const handleCoursesPageSizeChange = useCallback((newPageSize: number) => {
    setCoursesPageSize(newPageSize);
    setCoursesPage(1);
  }, []);

  const handleStacksPageSizeChange = useCallback((newPageSize: number) => {
    setStacksPageSize(newPageSize);
    setStacksPage(1);
  }, []);

  useEffect(() => {
    if (debouncedCourseQuery.isSettling) {
      return;
    }

    const searchCourses = async () => {
      const query = debouncedCourseQuery.value;

      if (!query || !query.trim()) {
        setCourseSearchResults([]);
        return;
      }

      try {
        setIsSearchingCourses(true);
        const response = await apiService.learning.searchUserCreatedCourses({
          q: query,
          learnerCount: true,
        });
        const searchCourses = response.data.courses.map((course) =>
          fromDTOGamePack(course)
        );
        setCourseSearchResults(searchCourses);
      } catch (error) {
        logger.error('Error searching courses:', error);
      } finally {
        setIsSearchingCourses(false);
      }
    };

    searchCourses();
  }, [debouncedCourseQuery.value, debouncedCourseQuery.isSettling]);

  useEffect(() => {
    if (debouncedStackQuery.isSettling) {
      return;
    }

    const searchStacks = async () => {
      const query = debouncedStackQuery.value;

      if (!query || !query.trim()) {
        setStackSearchResults([]);
        return;
      }

      try {
        setIsSearchingStacks(true);
        const response = await apiService.learning.searchUserCreatedStacks({
          q: query,
          learnerCount: true,
        });
        setStackSearchResults(response.data.stacks ?? []);
      } catch (error) {
        logger.error('Error searching stacks:', error);
      } finally {
        setIsSearchingStacks(false);
      }
    };

    searchStacks();
  }, [debouncedStackQuery.value, debouncedStackQuery.isSettling]);

  const handleCourseSearchChange = useCallback((query: string) => {
    setCourseSearchQuery(query);
  }, []);

  const handleStackSearchChange = useCallback((query: string) => {
    setStackSearchQuery(query);
  }, []);

  const clearCourseSearch = useCallback(() => {
    setCourseSearchQuery('');
    setCourseSearchResults([]);
  }, []);

  const clearStackSearch = useCallback(() => {
    setStackSearchQuery('');
    setStackSearchResults([]);
  }, []);

  const clearAllSearches = useCallback(() => {
    clearCourseSearch();
    clearStackSearch();
  }, [clearCourseSearch, clearStackSearch]);

  return {
    courses: coursesData?.data.courses || [],
    stacks: stacksData?.data.stacks || [],
    coursesPagination: coursesData?.data.pagination,
    stacksPagination: stacksData?.data.pagination,
    coursesPageSize,
    stacksPageSize,
    isLoading: coursesLoading || stacksLoading,
    error: coursesError || stacksError,
    handleCoursesPageChange,
    handleStacksPageChange,
    handleCoursesPageSizeChange,
    handleStacksPageSizeChange,
    refreshCourses,
    refreshStacks,

    // Course search
    courseSearchQuery,
    handleCourseSearchChange,
    courseSearchResults,
    isSearchingCourses: isSearchingCourses || debouncedCourseQuery.isSettling,
    isCourseQuerySettling: debouncedCourseQuery.isSettling,
    clearCourseSearch,

    // Stack search
    stackSearchQuery,
    handleStackSearchChange,
    stackSearchResults,
    isSearchingStacks: isSearchingStacks || debouncedStackQuery.isSettling,
    isStackQuerySettling: debouncedStackQuery.isSettling,
    clearStackSearch,

    clearAllSearches,
  };
}

type TabOption = 'courses' | 'stacks';

export function Component() {
  const navigate = useNavigate();
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const tabOptions: Option<TabOption>[] = [
    { label: 'Courses', value: 'courses' },
    { label: 'Stacks', value: 'stacks' },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<Option<TabOption>>(() => {
    const tab = searchParams.get('tab');
    return tabOptions.find((o) => o.value === tab) || tabOptions[0];
  });

  const switchTab = useLiveCallback((tab: Option<TabOption>) => {
    setSearchParams({ tab: tab.value });
    setActiveTab(tab);
    if (clearAllSearches) clearAllSearches();
  });

  const {
    courses,
    stacks,
    coursesPagination,
    stacksPagination,
    isLoading,
    error,
    handleCoursesPageChange,
    handleStacksPageChange,
    refreshCourses,
    refreshStacks,

    // Course search
    courseSearchQuery,
    handleCourseSearchChange,
    courseSearchResults,
    isSearchingCourses,
    clearCourseSearch,

    // Stack search
    stackSearchQuery,
    handleStackSearchChange,
    stackSearchResults,
    isSearchingStacks,
    clearStackSearch,

    clearAllSearches,
  } = useCreatedCoursesAndStacks();

  const { handleAssign } = useEnrollmentActions();
  const triggerCreateStackModal = useTriggerCreateStackModal();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const handleAssignCourse = useCallback(
    (pack: GamePack) => {
      const target = gamePackToEnrollmentTarget(pack);
      handleAssign(target, () => {
        toast.success('Course successfully assigned', { autoClose: 3000 });
        refreshCourses();
      });
    },
    [handleAssign, refreshCourses]
  );

  const handleAssignStack = useCallback(
    (stack: ModelsStack) => {
      const target: EnrollmentTarget = {
        type: 'stack',
        name: stack.name,
        id: stack.id,
      };
      handleAssign(target, () => {
        toast.success('Stack successfully assigned', { autoClose: 3000 });
        refreshStacks();
      });
    },
    [handleAssign, refreshStacks]
  );

  const handleSaveStack = async () => {
    try {
      const modalResp = await triggerCreateStackModal({
        onConfirm: async (name, courseIds) => {
          await apiService.learning.createStack({
            name,
            courseIds,
          });
          refreshStacks();
        },
      });

      if (modalResp.result === 'confirmed') {
        toast.success('Successfully created course stack', { autoClose: 3000 });
      }
    } catch (error) {
      logger.error('Failed to create stack:', error);
      toast.error('Failed to create course stack', { autoClose: 5000 });
    }
  };

  const handleDeleteCourse = async (id: string) => {
    const response = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: <DeleteCourseConfirmationModal />,
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
    });
    if (response.result !== 'confirmed') {
      return;
    }
    try {
      await apiService.learning.deleteCourse(id);
      toast.success('Course successfully deleted', { autoClose: 3000 });
      refreshCourses();
    } catch (e) {
      logger.error('Error deleting course', e);
    }
  };

  const handleDeleteStack = async (id: string) => {
    await apiService.learning.deleteStack(id);
    toast.success('Stack successfully deleted', { autoClose: 3000 });
    refreshStacks();
  };

  const handleEditCourse = async (id: string) => {
    navigate($path('/trainings/:id/edit', { id }));
  };

  const handleCourseDetails = async (id: string) => {
    navigate($path('/learning/admin/my-courses/:id', { id }));
  };

  const handleStackDetails = async (id: string) => {
    navigate($path('/learning/admin/my-courses/stacks/:id', { id }));
  };

  const toggleCourseSelection = useCallback((id: string) => {
    setSelectedCourses((prev) => {
      if (prev.includes(id)) {
        return prev.filter((courseId) => courseId !== id);
      } else {
        return [...prev, id];
      }
    });
  }, []);

  return (
    <div className='w-full h-full py-10 text-white max-w-screen-2xl mx-auto flex flex-col px-10'>
      {/* Header controls */}
      <div className='flex flex-row items-start justify-between mb-10'>
        <div className='flex flex-col gap-2'>
          <div className='text-2xl font-bold'>My Courses</div>
          <div className='text-sms text-icon-gray'>
            Manage your courses and course stacks
          </div>
        </div>
        <div className='flex flex-row gap-4 h-10 self-center'>
          <CreateCourseButton />
          <button
            type='button'
            onClick={handleSaveStack}
            className='flex items-center gap-2 bg-[#232325] text-white font-bold py-3 px-6 rounded-xl hover:bg-lp-gray-002 transition'
          >
            <span>Create Course Stack</span>
          </button>
        </div>
      </div>

      {/* Tab Control */}
      <div className='w-full flex justify-between mb-6'>
        <div className='w-54'>
          <SegmentedControl
            options={tabOptions}
            value={activeTab}
            onChange={switchTab}
            styles={{
              glider: 'bg-lp-gray-006 rounded-lg',
              track: 'rounded-xl p-1 border border-secondary',
              option: 'px-4 py-2 transition-colors text-sms',
              selectedOption: 'text-white',
              unselectedOption: 'text-gray-400 hover:text-white',
            }}
          />
        </div>

        {/* Search Bar  */}
        {activeTab.value === 'courses' && (
          <div className='relative w-72 pr-4'>
            <input
              type='text'
              value={courseSearchQuery}
              onChange={(e) => handleCourseSearchChange(e.target.value)}
              placeholder='Search courses...'
              className='w-full h-10 px-4 py-2 bg-black rounded-xl text-white border border-secondary focus:outline-none focus:ring-1 focus:ring-white'
            />
            {courseSearchQuery && (
              <button
                type='button'
                onClick={clearCourseSearch}
                className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white'
              >
                <span className='text-lg pr-4'>×</span>
              </button>
            )}
          </div>
        )}

        {activeTab.value === 'stacks' && (
          <div className='relative w-72 pr-4'>
            <input
              type='text'
              value={stackSearchQuery}
              onChange={(e) => handleStackSearchChange(e.target.value)}
              placeholder='Search stacks...'
              className='w-full h-10 px-4 py-2 bg-black rounded-xl text-white border border-secondary focus:outline-none focus:ring-1 focus:ring-white'
            />
            {stackSearchQuery && (
              <button
                type='button'
                onClick={clearStackSearch}
                className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white'
              >
                <span className='text-lg pr-4'>×</span>
              </button>
            )}
          </div>
        )}
      </div>

      <div className='flex-1 min-h-[600px] relative'>
        {/* Courses Tab */}
        {activeTab.value === 'courses' && (
          <div className='flex flex-col'>
            {/* Display search results when there's an active search */}
            {courseSearchQuery ? (
              <div>
                {isSearchingCourses ? (
                  <div className='flex justify-center items-center h-24'>
                    <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-white'></div>
                  </div>
                ) : courseSearchResults.length === 0 && !isSearchingCourses ? (
                  <div className='text-center py-8 text-gray-400'>
                    No courses found matching "{courseSearchQuery}"
                  </div>
                ) : (
                  <div>
                    <div className='mb-4 text-sm text-gray-400'>
                      Found {courseSearchResults.length}{' '}
                      {courseSearchResults.length === 1 ? 'course' : 'courses'}{' '}
                      matching "{courseSearchQuery}"
                    </div>
                    <LongCourseCardList
                      packs={courseSearchResults}
                      isStackMode={false}
                      selectedCourses={selectedCourses}
                      selectionOrder={(id) => selectedCourses.indexOf(id)}
                      onSelect={toggleCourseSelection}
                      onAssign={handleAssignCourse}
                      onEdit={(id) => handleEditCourse(id)}
                      onDelete={(id) => handleDeleteCourse(id)}
                      onDetails={(id) => handleCourseDetails(id)}
                    />
                  </div>
                )}
              </div>
            ) : (
              /* Display paginated courses when not searching */
              <div>
                {courses.length === 0 && !isLoading ? (
                  <TrainingGenAIStarter />
                ) : (
                  <LongCourseCardList
                    packs={courses.map((c) => fromDTOGamePack(c))}
                    isStackMode={false}
                    selectedCourses={selectedCourses}
                    selectionOrder={(id) => selectedCourses.indexOf(id)}
                    onSelect={toggleCourseSelection}
                    onAssign={handleAssignCourse}
                    onEdit={(id) => handleEditCourse(id)}
                    onDelete={(id) => handleDeleteCourse(id)}
                    onDetails={(id) => handleCourseDetails(id)}
                  />
                )}
              </div>
            )}

            {/* Only show pagination when not searching */}
            {!courseSearchQuery && courses.length > 0 && coursesPagination && (
              <div className='py-4'>
                <PaginationNav
                  currentPage={coursesPagination.currentPage}
                  totalPages={coursesPagination.totalPages}
                  onPageChange={handleCoursesPageChange}
                  totalItems={coursesPagination.totalItems}
                  isLoading={isLoading}
                />
              </div>
            )}
          </div>
        )}

        {/* Stacks Tab */}
        {activeTab.value === 'stacks' && (
          <div className='flex flex-col'>
            {stackSearchQuery ? (
              <div>
                {isSearchingStacks ? (
                  <div className='flex justify-center items-center h-24'>
                    <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-white'></div>
                  </div>
                ) : stackSearchResults.length === 0 && !isSearchingStacks ? (
                  <div className='text-center py-8 text-gray-400'>
                    No stacks found matching "{stackSearchQuery}"
                  </div>
                ) : (
                  <div>
                    <div className='mb-4 text-sm text-gray-400'>
                      Found {stackSearchResults.length}{' '}
                      {stackSearchResults.length === 1 ? 'stack' : 'stacks'}{' '}
                      matching "{stackSearchQuery}"
                    </div>
                    <div className='pr-4 pb-4'>
                      <StackCardList
                        stacks={stackSearchResults}
                        onAssign={handleAssignStack}
                        onDelete={handleDeleteStack}
                        onDetails={handleStackDetails}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className='pr-4 pb-4'>
                  <StackCardList
                    stacks={stacks}
                    onAssign={handleAssignStack}
                    onDelete={handleDeleteStack}
                    onDetails={handleStackDetails}
                  />
                </div>
                {stacks.length > 0 && stacksPagination && (
                  <div className='py-4'>
                    <PaginationNav
                      currentPage={stacksPagination.currentPage}
                      totalPages={stacksPagination.totalPages}
                      onPageChange={handleStacksPageChange}
                      totalItems={stacksPagination.totalItems}
                      isLoading={isLoading}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Loading state */}
        {isLoading && (
          <div className='absolute inset-0 flex items-center justify-center'>
            <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-white'></div>
          </div>
        )}

        {/* Error state */}
        {error && (
          <div className='absolute inset-0 flex items-center justify-center bg-black/50'>
            <div className='text-center py-4 text-red-500'>
              Failed to load content.
              <button
                type='button'
                onClick={() =>
                  activeTab.value === 'courses'
                    ? refreshCourses()
                    : refreshStacks()
                }
                className='text-blue-500 hover:underline'
              >
                Retry
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
