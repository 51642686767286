import { useEffect, useRef, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { match } from 'ts-pattern';

import { EnumsGamePackUGCCreationStatus } from '@lp-lib/api-service-client/public';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { type GamePack } from '../../types/game';
import { fromMediaDTO } from '../../utils/api-dto';
import { MediaUtils } from '../../utils/media';
import { CopyIcon } from '../icons/CopyIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { GameIcon } from '../icons/GameIcon';
import { NewWindowIcon } from '../icons/NewWindowIcon';
import { OptionsIcon } from '../icons/OptionsIcon';
import { Loading } from '../Loading';
import { useOpenLMSLinkExportModal } from './LMSLinkExport';
import { useOpenScormExportModal } from './ScormExport';
import { isCreationDone, pullCourseCreationStatus } from './utils';

interface GamePackCardProps {
  pack: GamePack;
  onAssign: (pack: GamePack) => void;
  onDetails: (id: string) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  showHeaders?: boolean;
  isStackMode?: boolean;
  isSelected?: boolean;
  onSelect?: (id: string) => void;
  selectionOrder?: number;
  learners: number;
}

export const LongCourseCard = ({
  pack,
  onAssign,
  onDetails,
  onEdit,
  onDelete,
  showHeaders = false,
  isStackMode = false,
  learners,
}: GamePackCardProps) => {
  const openScormExportModal = useOpenScormExportModal();
  const openLMSLinkExportModal = useOpenLMSLinkExportModal();
  const [creationStatus, setCreationStatus] = useState(
    pack.ugcSettings?.creationStatus
  );
  const [coverSrc, setCoverSrc] = useState(
    MediaUtils.PickMediaUrl(pack.cover) || placeholder
  );

  useEffect(() => {
    if (isCreationDone(creationStatus)) return;
    const aborter = new AbortController();
    pullCourseCreationStatus(
      pack.id,
      (updatedPack) => {
        setCreationStatus(updatedPack.ugcSettings?.creationStatus);
        if (!updatedPack.cover) return;
        if (updatedPack.cover.id !== pack.cover?.id) {
          const newCoverSrc = MediaUtils.PickMediaUrl(
            fromMediaDTO(updatedPack.cover)
          );
          if (!newCoverSrc) return;
          setCoverSrc(newCoverSrc);
        }
      },
      aborter.signal,
      10000
    ).catch(console.error);
    return () => {
      aborter.abort();
    };
  }, [creationStatus, pack.cover?.id, pack.id]);

  return (
    <div className='flex flex-col gap-2'>
      {showHeaders && (
        <div className='flex items-center px-4 text-gray-400 text-sm mr-8'>
          {isStackMode && <div className='w-8' />}
          <div className='flex-1'>Name</div>
          <div className='flex items-center gap-16'>
            <div className='w-20 text-start'>Learners</div>
            <div className='w-32 text-start'>Created On</div>
          </div>
          <div className='w-32' />
        </div>
      )}

      <div
        className='w-full bg-[#17171A] rounded-lg p-4 flex items-center justify-between gap-4 border-lp-gray-003 border hover:bg-[#1f1f22] transition-colors hover:cursor-pointer'
        onClick={() => onDetails(pack.id)}
      >
        {/* Left section with thumbnail and title */}
        <div className='flex items-center gap-4 flex-1'>
          <div className='w-21 h-12 rounded-lg bg-gray-700 overflow-hidden'>
            <img
              src={coverSrc}
              alt={pack.name}
              className='w-full h-full object-cover'
            />
          </div>
          <span className='text-white font-semibold truncate max-w-100'>
            {pack.name}
          </span>
        </div>

        {/* Center section with stats */}
        <div className='flex items-center gap-16'>
          <div className='w-20 text-center'>
            <span className='text-white text-sms'>{learners}</span>
          </div>
          <div className='w-32 text-center'>
            <span className='text-white text-sms'>
              {new Date(pack.createdAt).toLocaleDateString()}
            </span>
          </div>
        </div>

        {/* Right section with actions */}
        <div className='flex items-center gap-4 w-32 justify-end ml-8'>
          {match(creationStatus)
            .with(
              EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusQueued,
              () => <Loading text='Queued' containerClassName='text-sms' />
            )
            .with(
              EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusInProgress,
              () => <Loading text='In Progress' containerClassName='text-sms' />
            )
            .with(
              EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusFailed,
              () => (
                <>
                  <div className='text-red-002 text-sms'>Failed</div>
                  <CourseActionsMenu onDelete={() => onDelete(pack.id)} />
                </>
              )
            )
            .otherwise(() => {
              if (isStackMode) return null;
              return (
                <>
                  <button
                    type='button'
                    onClick={(e) => {
                      e.stopPropagation();
                      onAssign(pack);
                    }}
                    className='px-6 py-2 bg-[#232325] hover:bg-[#2A2A2D] text-white rounded-lg transition-colors text-sms'
                  >
                    Assign
                  </button>
                  <CourseActionsMenu
                    onDetails={() => onDetails(pack.id)}
                    onEdit={() => onEdit(pack.id)}
                    onDelete={() => onDelete(pack.id)}
                    onDownloadScorm={() => openScormExportModal(pack)}
                    onCopyLMSLink={() => openLMSLinkExportModal(pack.id)}
                  />
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

interface CourseActionsMenuProps {
  onDetails?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onDownloadScorm?: () => void;
  onCopyLMSLink?: () => void;
}

export function CourseActionsMenu({
  onDetails,
  onEdit,
  onDelete,
  onDownloadScorm,
  onCopyLMSLink,
}: CourseActionsMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'bottom-end',
      interactive: true,
      offset: [0, 8],
      visible: isOpen,
      onVisibleChange: (next) => {
        setIsOpen(next);
      },
      trigger: 'click',
    });

  const tooltipRef = useRef<HTMLDivElement>(null);
  useOutsideClick(tooltipRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleAction = (action: () => void) => {
    action();
    setIsOpen(false);
  };

  return (
    <div className='relative inline-block'>
      <button
        ref={setTriggerRef}
        type='button'
        className='p-2 hover:bg-[#232325] rounded-lg transition-colors'
        onClick={handleToggle}
      >
        <OptionsIcon className='w-5 h-5 fill-current text-white' />
      </button>

      {visible && isOpen && (
        <div
          ref={(node) => {
            setTooltipRef(node);
          }}
          {...getTooltipProps({
            className:
              'z-50 w-50 bg-black text-white rounded-xl p-1 border border-secondary',
          })}
        >
          {onDetails && (
            <button
              type='button'
              onClick={(event) => {
                event.stopPropagation();
                handleAction(onDetails);
              }}
              className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms'
            >
              <GameIcon className='w-6 h-6 fill-current text-white pr-2' />
              Course Details
            </button>
          )}
          {onEdit && (
            <button
              type='button'
              onClick={(event) => {
                event.stopPropagation();
                handleAction(onEdit);
              }}
              className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms'
            >
              <EditIcon className='w-6 h-6 fill-current text-white pr-2' />
              Edit Course
            </button>
          )}
          {onCopyLMSLink && (
            <button
              type='button'
              onClick={(event) => {
                event.stopPropagation();
                handleAction(onCopyLMSLink);
              }}
              className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms'
            >
              <CopyIcon className='w-6 h-6 fill-current text-white pr-2' />
              Copy LMS Link
            </button>
          )}
          {onDownloadScorm && (
            <button
              type='button'
              onClick={(event) => {
                event.stopPropagation();
                handleAction(onDownloadScorm);
              }}
              className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms'
            >
              <NewWindowIcon className='w-6 h-6 fill-current text-white pr-2' />
              Download SCORM
            </button>
          )}
          {onDelete && (
            <button
              type='button'
              onClick={(event) => {
                event.stopPropagation();
                handleAction(onDelete);
              }}
              className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms text-red-500'
            >
              <DeleteIcon className='w-6 h-6 fill-current pr-2' />
              Delete Course
            </button>
          )}
        </div>
      )}
    </div>
  );
}
